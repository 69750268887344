import PageLayout from '../layout/PageLayout'
import ProductsHero from '../component/products component/ProductsHero'
import ManagementsSection from '../component/section/ManagementsSection'
import { servicesData } from '../utils/data'
import { useEffect } from 'react'
import ServiceHero from '../component/service component/ServiceHero'

type Props = {}

const Service = (props: Props) => {
  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [])

  return (
    <PageLayout>
      <ServiceHero />
      {servicesData.map((system) => (
        <ManagementsSection key={system.id} system={system} />
      ))}{' '}
    </PageLayout>
  )
}

export default Service
