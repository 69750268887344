import React from 'react'

const RDMSBody = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="flex flex-col space-y-8">
        {/* Introduction Section */}
        <div>
          <p className="text-lg text-gray-700 leading-relaxed font-lato">
            Our Revenue Management System (ReMS) is a comprehensive,
            custom-built solution designed to seamlessly integrate all revenue
            streams, including Pay-As-You-Earn (PAYE), Direct Assessment, Motor
            Vehicle Administration, Withholding Tax, Capital Gains Tax, MDA
            collections, and Local Government fees and levies. This advanced
            solution ensures efficient revenue collection, management, and
            monitoring, making it a powerful tool for tax authorities.
          </p>
        </div>

        {/* Taxpayer Lifecycle Management */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 font-lato">
            Taxpayer Lifecycle Management
          </h2>
          <p className="text-gray-600 font-lato">
            ReMS covers the entire taxpayer lifecycle—from enrollment,
            profiling, and assessment to notification, settlement, exception
            management, and the issuance of Tax Clearance Certificates. This
            ensures a smooth, end-to-end process, allowing tax authorities to
            manage all aspects of taxpayer engagement and compliance with ease.
          </p>
        </div>

        {/* Self-Service Portal */}
        <div>
          <h2 className="text-2xl font-semibold font-lato text-gray-800 mb-4">
            Self-Service Portal
          </h2>
          <p className="text-gray-600 mb-4 font-lato">
            The Self-Service Portal empowers taxpayers by giving them direct
            access to manage their tax-related activities. Through this portal,
            users can:
          </p>
          <ul className="list-disc pl-6 space-y-2 font-lato text-gray-600">
            <li>Verify their tax details</li>
            <li>Onboard as new taxpayers</li>
            <li>Access and pay bills</li>
            <li>View tax assessments and settlement history</li>
            <li>Request Tax Clearance Certificates (eTCCs)</li>
          </ul>
        </div>

        {/* Admin Portal */}
        <div>
          <h2 className="text-2xl font-semibold text-gray-800 font-lato mb-4">
            Admin Portal
          </h2>
          <p className="text-gray-600 mb-4 font-lato">
            The Admin Portal offers tax authorities full control and visibility
            over the entire revenue management process. Features of the Admin
            Portal include:
          </p>
          <ul className="list-disc pl-6 space-y-2 font-lato text-gray-600">
            <li>
              <strong>Taxpayer Management:</strong> Admins can view and manage
              taxpayer records, update profiles, and monitor tax compliance
            </li>
            <li>
              <strong>Revenue Stream Monitoring:</strong> Track and analyze
              collections across various revenue streams and geographic regions
            </li>
            <li>
              <strong>Assessment & Notification Management:</strong> Generate
              and send tax assessments, notices, and reminders to taxpayers in a
              timely manner
            </li>
            <li>
              <strong>Exception Management:</strong> Identify and handle
              exceptions, disputes, or inconsistencies in tax data, ensuring
              swift resolution
            </li>
            <li>
              <strong>Reporting & Analytics:</strong> Generate detailed reports
              and analytics to evaluate revenue trends, identify gaps, and make
              informed decisions
            </li>
          </ul>
          <p className="text-gray-600 font-lato mt-4">
            The Admin Portal is designed for flexibility, allowing customization
            to meet the specific needs of different Revenue Authorities.
          </p>
        </div>

        {/* Mobile and POS Integration */}
        <div>
          <h2 className="text-2xl font-lato font-semibold text-gray-800 mb-4">
            Mobile and POS Integration
          </h2>
          <p className="text-gray-600 font-lato">
            ReMS is fully compatible with smartphones and Point-Of-Sale (POS)
            systems, providing a modern, user-friendly application that enhances
            revenue collection in the mobile sector, informal economy, MDA
            services, transport sector, and beyond. Tax authorities can utilize
            these systems for real-time taxpayer verification, onboarding,
            assessments, and bill generation.
          </p>
        </div>

        {/* Scalability Section */}
        <div>
          <h2 className="text-2xl font-lato font-semibold text-gray-800 mb-4">
            Scalability & Customization
          </h2>
          <p className="text-gray-600 font-lato">
            The Revenue Management System is built to scale as needed, whether
            your Revenue Authority is large or small. It is fully customizable
            to meet the specific requirements and regulatory frameworks of any
            jurisdiction. This flexibility ensures that the system adapts to
            evolving tax policies, emerging revenue streams, and diverse
            organizational needs. With ReMS, revenue authorities can manage
            taxes efficiently, boost taxpayer compliance, and improve
            operational transparency—resulting in increased revenue collection
            and better service delivery.
          </p>
        </div>

        <div className="flex items-center mt-5 justify-center">
          <a
            href="https://blouzatechnologieslimited.zohobookings.com/#/4691959000000052006"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              className="
      px-6 py-3
      font-medium 
      border-2
      shadow-custom-black 
      transition-all duration-300
      relative
      overflow-hidden
    "
            >
              <span className="relative z-10 text-black">Schedule Demo</span>
              <div
                className="
        absolute inset-0 
        bg-black 
        transform translate-y-full 
        group-hover:translate-y-0 
        transition-transform duration-300
      "
              ></div>
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default RDMSBody
