import { useState } from 'react'
import { Menu, X } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const navItems = [
    { label: 'Home', href: '/' },
    { label: 'Products', href: '/products' },
    { label: 'Services', href: '/services' },
  ]

  const handleContactClick = () => {
    navigate('/')

  
    setTimeout(() => {
      const contactSection = document.getElementById('contact-us')
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100) // Adjust the delay if needed
  }

  return (
    <nav className="relative  shadow-sm md:border-b md:border-[#8B8D91]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <img
            src="/images/Blouza-logo.svg"
            alt="Blouza Logo"
            className="h-10 w-auto"
          />

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:justify-between md:space-x-8">
            <ul className="flex space-x-8">
              {navItems.map((item) => (
                <li key={item.label}>
                  <a
                    href={item.href}
                    className="text-[#1E46F8] hover:text-[#1E46F8]/80 transition-colors font-lato"
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <button
            className="md:block hidden border border-[#1E46F878] text-[#1E46F8] px-4 py-2 hover:bg-[#1E46F8] hover:text-white transition-colors font-lato"
            onClick={handleContactClick}
          >
            Contact Us
          </button>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-[#1E46F8] hover:text-[#1E46F8]/80 p-2"
              aria-label="Toggle menu"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg">
            {navItems.map((item) => (
              <a
                key={item.label}
                href={item.href}
                className="block px-3 py-2 text-[#1E46F8] hover:bg-[#1E46F8]/10 rounded-md font-lato"
              >
                {item.label}
              </a>
            ))}
            <div className="px-3 py-2">
              <button className="w-full border border-[#1E46F878] text-[#1E46F8] px-4 py-2 hover:bg-[#1E46F8] hover:text-white transition-colors font-lato">
                Contact Us
              </button>
            </div>
          </div>
        </div>
      )}
    </nav>
  )
}

export default Navbar
