export const managementSystemsData = [
  {
    id: 'rms',
    title: 'Revenue Management System',
    firstText:
      'Our Revenue Management System (ReMS) is a comprehensive, custom-built solution designed to seamlessly integrate all revenue streams, including Pay-As-You-Earn (PAYE), Direct Assessment, Motor Vehicle Administration, Withholding Tax, Capital Gains Tax, MDA collections, and Local Government fees and levies. This advanced solution ensures efficient revenue collection, management, and monitoring, making it a powerful tool for tax authorities.',
    secondTextHeader: 'Taxpayer Lifecycle Management',
    secondText: `ReMS covers the entire taxpayer lifecycle—from enrollment, profiling, and assessment to notification, settlement, exception management, and the issuance of Tax Clearance Certificates. This ensures a smooth, end-to-end process, allowing tax authorities to manage all aspects of taxpayer engagement and compliance with ease.`,
    continueReading: true,
    backgroundColor: '#F6F2E9',
    buttonText: 'Schedule Demo',
    buttonColor: 'white',
    buttonLink:
      'https://blouzatechnologieslimited.zohobookings.com/#/4691959000000052006',
  },
  {
    id: 'hms',
    title: 'Hospital Management System',
    firstText:
      'The Hospital Management System is an all-in-one solution designed to streamline patient care, operations, and administrative processes for healthcare facilities. It simplifies patient management, from appointment scheduling and medical record keeping to billing and discharge, ensuring seamless care continuity and improved patient satisfaction.',
    secondText: `In addition to patient care, HMS optimizes hospital operations by automating inventory management, staff scheduling, and resource allocation, enabling healthcare providers to focus more on delivering quality care. The system enhances workflow efficiency, minimizes delays, and supports better decision-making for hospital staff.`,
    thirdText:
      'Designed to be user-friendly and highly customizable, HMS can adapt to the unique needs of any healthcare facility. With a centralized platform that integrates clinical, operational, and administrative functions, HMS enhances overall efficiency, reduces operational costs, and improves the quality of care delivered to patients.',
    backgroundColor: 'white',
    buttonText: 'Schedule Demo',
    buttonColor: '#F6F2E9',
    buttonLink:
      'https://blouzatechnologieslimited.zohobookings.com/#/4691959000000052006',
  },
  {
    id: 'sms',
    title: 'School Management System',
    firstText:
      'The School Management System is a comprehensive solution designed to simplify enrollment, academic processes, and communication, making school administration more efficient and effective. SMS streamlines student admissions, record-keeping, and attendance tracking, ensuring smooth and organized enrollment processes that save time and reduce administrative workload.',
    secondText: `In academics, it offers tools for managing class schedules, assignments, exams, and grading, providing teachers with an efficient platform to monitor and support student progress. By centralizing academic activities, SMS fosters an organized learning environment that benefits both staff and students.`,
    thirdText:
      'It also enhances communication between educators, students, and parents through integrated messaging and notifications, ensuring that everyone stays informed and connected. With its user-friendly, customizable interface, SMS adapts to the unique needs of any educational institution, ultimately supporting better school management and a more productive learning experience.',
    backgroundColor: '#F6F2E9',
    buttonText: 'Schedule Demo',
    buttonColor: 'white',
    buttonLink:
      'https://blouzatechnologieslimited.zohobookings.com/#/4691959000000052006',
  },
  {
    id: 'tms',
    title: 'Transport Management System',
    firstText:
      'Transport Management System offers a unified platform designed to streamline fare collection and optimize vehicle and driver management for public transportation authorities. With automated fare collection, it provides secure, real-time tracking of revenue while minimizing cash handling and reducing fraud, ensuring financial efficiency and transparency.',
    secondText: `TMS enables seamless vehicle and driver management by tracking maintenance schedules, monitoring driver performance, and optimizing routes. This centralized management approach enhances safety and efficiency across the fleet, ensuring that resources are allocated effectively and downtime is minimized.`,
    thirdText:
      'Built to be scalable and customizable, it adapts to the needs of any transit authority, accommodating fleets of all sizes and jurisdictional requirements. By integrating fare, vehicle, and driver data into one system, TMS supports data-driven decisions, ultimately improving operational efficiency, reducing costs, and elevating the passenger experience.',
    backgroundColor: 'white',
    buttonText: 'Schedule Demo',
    buttonColor: '#F6F2E9',
    buttonLink:
      'https://blouzatechnologieslimited.zohobookings.com/#/4691959000000052006',
  },
  {
    id: 'jms',
    title: 'Judiciary Management System',
    firstText:
      'The Judiciary Management System is an end-to-end solution crafted to streamline filings, case management, and court scheduling, ensuring efficient and organized legal operations. JMS simplifies the filing process by digitizing case submissions, document management, and tracking, making it easy for court staff, legal professionals, and litigants to access and manage case information.',
    secondText: `The system centralizes case management, enabling courts to efficiently handle case records, monitor case statuses, and manage workflows. With automated scheduling, JMS organizes court dates, reduces scheduling conflicts, and improves time management for court personnel, helping to minimize delays and enhance operational flow.`,
    thirdText:
      'Flexible and secure, it is built to meet the unique needs of judicial institutions of all sizes. By integrating these key functions into one platform, it promotes transparency, accountability, and streamlined operations within the judiciary, ultimately supporting fair and timely legal proceedings.',
    backgroundColor: '#F6F2E9',
    buttonText: 'Schedule Demo',
    buttonColor: 'white',
    buttonLink:
      'https://blouzatechnologieslimited.zohobookings.com/#/4691959000000052006',
  },

  {
    id: 'pms',
    title: 'Payroll Management System',
    firstText:
      'Our Payroll solution is a robust solution designed to automate compensation, benefits, and compliance, ensuring smooth and accurate payroll operations. It handles salary calculations, tax deductions, and benefits administration with precision, minimizing errors and saving time for HR teams.',
    secondText: `With automated compliance features, it keeps your organization up-to-date with regulatory requirements, reducing the risk of costly errors and ensuring seamless payroll processing. The system’s intuitive design allows for easy management of payroll cycles, adjustments, and reporting, empowering teams to manage payroll with confidence.`,
    thirdText:
      'Flexible and scalable, it can be tailored to fit organizations of any size, adapting to specific payroll structures and benefits packages. By integrating all payroll functions into one solution, it enhances efficiency, improves accuracy, and supports a streamlined payroll experience for both employees and administrators.',
    backgroundColor: 'white',
    buttonText: 'Schedule Demo',
    buttonColor: '#F6F2E9',
    buttonLink:
      'https://blouzatechnologieslimited.zohobookings.com/#/4691959000000052006',
  },
]

export const servicesData = [
  {
    id: 'pdi',
    title: 'Digital Public Infrastructure (DPI)',
    firstText: `Blouza Tech's Digital Public Infrastructure solutions are designed to transform government systems for improved service delivery. We create modern, efficient platforms that support the backbone of digital governance, from secure data management to seamless inter-agency collaboration. Our team works closely with public sector clients to understand their unique needs and design scalable, customized solutions that empower governments to serve citizens more effectively.`,
    secondText: `By partnering with Blouza Tech, governments can benefit from reliable, integrated infrastructure that enhances transparency, streamlines operations, and facilitates citizen engagement. Our solutions improve data accessibility, reduce manual workloads, and support data-driven decision-making, ultimately creating a more responsive and accountable public service environment.`,
    backgroundColor: '#F6F2E9',
    buttonText: 'Let`s Talk',
    buttonColor: 'white',
    buttonLink:
      'https://blouzatechnologieslimited.zohobookings.com/#/4691959000000042054',
  },
  {
    id: 'erp',
    title: 'Enterprise Resource Planning',
    firstText:
      'At Blouza Tech, we provide comprehensive Enterprise Resource Planning (ERP) solutions tailored to meet the specific demands of your organization. Our ERP systems integrate front-end applications with core back-end technology, covering everything from inventory and financials to human resources and customer management. We collaborate closely with clients to design ERP solutions that are fully customized, ensuring a seamless fit with your operational processes.',
    secondText: `Working with Blouza Tech for ERP development means gaining a system that drives efficiency, reduces operational costs, and enhances productivity. With streamlined workflows and centralized data, our ERP solutions enable better visibility across departments, improve resource allocation, and support informed, data-backed decision-making.`,
    backgroundColor: 'white',
    buttonText: 'Let`s Talk',
    buttonColor: '#F6F2E9',
    buttonLink:
      'https://blouzatechnologieslimited.zohobookings.com/#/4691959000000042054',
  },
  {
    id: 'dpa',
    title: 'Digital Process Automation',
    firstText: `Blouza Tech's Digital Process Automation services help organizations optimize workflows and minimize manual tasks, transforming everyday operations for maximum efficiency. Our automation solutions are customized to target high-impact areas, from document handling to data processing and employee onboarding. We work with clients to identify and automate key processes, ensuring smoother operations and significant time savings.`,
    secondText: `Partnering with Blouza Tech for Digital Process Automation allows your organization to increase efficiency, reduce human error, and focus on strategic initiatives. By automating repetitive tasks, we enable employees to dedicate their time to higher-value work, improving productivity and supporting long-term growth.`,
    backgroundColor: '#F6F2E9',
    buttonText: 'Let`s Talk',
    buttonColor: 'white',
    buttonLink:
      'https://blouzatechnologieslimited.zohobookings.com/#/4691959000000042054',
  },
]
