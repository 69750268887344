import React, { ReactNode, FC } from 'react'
import Footer from '../component/Footer'
import Navbar from '../component/Navbar/Navbar'

interface PageLayoutProps {
  children: ReactNode
}

const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <main className="flex-grow container">{children}</main>

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default PageLayout
