import { CallCalling, Message } from 'iconsax-react'

const ContactUs = () => {
  return (
    <div
      id="contact-us"
      className="font-lato font-bold w-full px-4 py-16 bg-gray-50 "
    >
      <div className="text-center mb-12">
        <h2 className="text-3xl font-lato font-bold tracking-tight text-gray-900">
          Contact Us
        </h2>
      </div>
      <div className="max-w-6xl mx-auto shadow p-2 rounded-[10px] bg-[#F1F1F2]">
        {/* Header */}

        <div className="grid lg:grid-cols-2">
          {/* Contact Information Card */}
          <div className="bg-[#1E46F8] relative p-8 rounded-lg shadow-sm">
            <img
              src="/images/contact-us-rings.svg"
              alt=""
              className="absolute bottom-0 right-0 z-10"
            />
            <div className="mb-20">
              <h3 className="text-2xl font-semibold mb-2 font-lato text-white">
                Reach Out to Us
              </h3>
              <p className="font-lato text-[#C9C9C9] font-normal text-lg">
                or just send us a message
              </p>
            </div>

            <div className="space-y-6 relative z-20">
              <div className="flex items-center space-x-4 group">
                <CallCalling className="w-6 h-6 text-[#FFFFFF]" />
                <div className="flex flex-col">
                  <span className="text-[16px] font-normal font-lato text-[#FFFFFF]">
                    +234 805 561 9258{' '}
                  </span>
                </div>
              </div>

              <div className="flex items-center space-x-4 group">
                <Message className="w-6 h-6 text-[#FFFFFF]" />
                <div className="flex flex-col">
                  <span className="text-[16px] font-normal font-lato text-[#FFFFFF]">
                    contact@blouzatech.ng{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form Card */}
          <div className="p-8">
            <form className="space-y-6 bg-inherit">
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label
                    htmlFor="firstName"
                    className="font-lato block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    placeholder="Enter your first name"
                    className="w-full font-lato font-[500] px-3 py-2 border-b bg-transparent border-gray-300  focus:outline-none  focus:border-b-blue-500"
                  />
                </div>

                <div className="space-y-2">
                  <label
                    htmlFor="lastName"
                    className="block font-lato text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    placeholder="Enter your last name"
                    className="font-lato w-full px-3 font-[500] py-2 border-b bg-transparent border-gray-300  focus:outline-none  focus:border-b-blue-500"
                  />
                </div>

                <div className="space-y-2">
                  <label
                    htmlFor="email"
                    className="block font-lato text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    placeholder="Enter your email"
                    className="font-lato w-full px-3 font-[500] py-2 border-b border-gray-300 bg-transparent focus:outline-none  focus:border-b-blue-500"
                  />
                </div>

                <div className="space-y-2">
                  <label
                    htmlFor="phone"
                    className="block font-lato text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <input
                    id="phone"
                    type="tel"
                    placeholder="Enter your phone number"
                    className="font-lato w-full font-[500] px-3 py-2 border-b bg-transparent border-gray-300 focus:outline-none  focus:border-b-blue-500"
                  />
                </div>
              </div>

              <div className="space-y-2 ">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium font-lato text-gray-700"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  placeholder="Write your message here..."
                  rows={4}
                  className="resize-none font-lato w-full px-3 font-[500] py-2 border-b border-gray-300  focus:outline-none bg-transparent  focus:border-b-blue-500"
                ></textarea>
              </div>

              <div className="w-full flex items-end justify-end">
                <button
                  type="submit"
                  className="font-lato text-[500] w-full sm:w-auto px-6 py-3 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
