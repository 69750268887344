import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import HomeHero from '../component/HomeHero'
import WhoWeAre from '../component/WhoWeAre'
import ProductShowcase from '../component/ProductShowcase'
import ITServices from '../component/ITServices'
import ContactUs from '../component/ContactUs'
import Footer from '../component/Footer'

type Props = {}

const Home = (props: Props) => {
  return (
    <div>
      <div className="bg-[#F6F2E9]">
        <Navbar />
        <HomeHero />
      </div>
      <WhoWeAre />
      <ProductShowcase />
      <ITServices />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default Home
