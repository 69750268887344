const Footer = () => {
  const socialLinks = [
    {
      id: 'linkedin',
      icon: '/images/LinkedIn.svg',
      href: 'https://www.linkedin.com/company/blouza-tech',
      alt: 'LinkedIn',
    },
  ]

  const products = [
    {
      id: 'rms',
      name: 'Revenue Management System',
      href: '/products#rms',
    },
    {
      id: 'hms',
      name: 'Hospital Management System',
      href: '/products#hms',
    },
    {
      id: 'sms',
      name: 'School Management System',
      href: '/products#sms',
    },
    {
      id: 'jms',
      name: 'Judiciary Management System',
      href: '/products#jms',
    },
    {
      id: 'tms',
      name: 'Transport Management System',
      href: '/products#tms',
    },
  ]

  const services = [
    {
      id: 'pdi',
      name: 'Digital Public Infrastructure',
      href: '/services#dpi',
    },
    {
      id: 'erp',
      name: 'Enterprise Resource Planning',
      href: '/services#erp',
    },
    {
      id: 'dpa',
      name: 'Digital Process Automation',
      href: '/services#dpa',
    },
  ]

  return (
    <footer className="bg-[#1E46F8] text-white">
      <div className="border-b border-white/20">
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Company Info */}
            <div className="space-y-6">
              <img
                src="/images/blouza-footer-logo.svg"
                alt="Blouza Technologies"
                className="h-auto w-auto"
              />
              <div className="space-y-3">
                <p className="font-semibold font-lato">Follow us</p>
                <div className="flex space-x-4">
                  {socialLinks.map((social, index) => (
                    <a
                      key={social.id}
                      href={social.href}
                      className="transition-transform hover:scale-110"
                    >
                      <img
                        src={social.icon}
                        alt={social.alt}
                        className="h-6 w-6"
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>

            {/* Products */}
            <div className="space-y-4">
              <h2 className="text-lg font-semibold font-lato">Products</h2>
              <div className="flex flex-col space-y-3">
                {products.map((product, index) => (
                  <a
                    key={product.id}
                    href={product.href}
                    className="text-gray-200 hover:text-white transition-colors font-normal font-lato duration-200"
                  >
                    {product.name}{' '}
                  </a>
                ))}
              </div>
            </div>

            {/* Services */}
            <div className="space-y-4">
              <h2 className="text-lg font-semibold font-lato">Services</h2>
              <div className="flex flex-col space-y-3">
                {services.map((service, index) => (
                  <a
                    key={service.id}
                    href={service.href}
                    className="text-gray-200 hover:text-white font-lato transition-colors duration-200 font-normal"
                  >
                    {service.name}
                  </a>
                ))}
              </div>
            </div>

            {/* Contact */}
            <div className="space-y-4">
              <h2 className="text-lg font-semibold font-lato">Contact us</h2>
              <div className="flex flex-col space-y-3">
                <a
                  href="mailto:contact@blouzatech.ng"
                  className="font-lato text-gray-200 hover:text-white transition-colors font-normal duration-200"
                >
                  contact@blouzatech.ng
                </a>
                <a
                  href="tel:+2348055619258"
                  className="text-gray-200 hover:text-white  font-lato  font-normal transition-colors duration-200"
                >
                  +234 805 561 9258
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className="max-w-7xl mx-auto px-4 py-6">
        <p className="text-sm text-gray-200 text-center md:text-left font-lato">
          Blouza Technologies Limited, © {new Date().getFullYear()} All rights
          reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
