import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import RMSDHero from '../component/products component/RMSDHero'
import RDMSBody from '../component/products component/RDMSBody'

type Props = {}

const RevenueManagementSystemDetails = (props: Props) => {
  return (
    <div>
      <div className="bg-[#F6F2E9]">
        <Navbar />
        <RMSDHero />
      </div>
      <RDMSBody />
    </div>
  )
}

export default RevenueManagementSystemDetails
