import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './page/Home'
import Products from './page/Products'
import Service from './page/Service'
import RevenueManagementSystemDetails from './page/RevenueManagementSystemDetails'

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Service />} />
          <Route path="/revenue-management-system" element={<RevenueManagementSystemDetails />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
